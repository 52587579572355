<script>
  import router from "page";
  import PostcodeFinder from "./pages/PostcodeFinder.svelte";
  import UprnFinder from "./pages/UprnFinder.svelte";
  import Header from "./components/Header.svelte";

  // Set up the page router
  let page;
  router("/", () => {
    page = PostcodeFinder;
  });
  router("/UprnSearch", () => {
    page = UprnFinder;
  });
  router.start();

  // Navigation properties
  $: isHomePage = page === PostcodeFinder;
  $: title = page === PostcodeFinder ? "Postcode search" : "Uprn search";
</script>

<Header {title} />
<main class="container">
  <p class="mt-4">
    {#if isHomePage}
      Search for an address by postcode or <a href="UprnSearch">uprn</a>
    {:else}
      Search for an address by <a href="/">postcode</a> or uprn
    {/if}
  </p>
  <svelte:component this={page} />
</main>
