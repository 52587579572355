<script>
  export let title;
</script>

<header class="p-4">
  <div class="container">
    <div class="row">
      <div class="col col-xs-6 col-md-3">
        <a
          href="https://www.york.gov.uk/"
          class="header-cyc-logo-container black-hover-effect"
          ><img
            src="/york-logo.svg"
            alt="City of York Council Logo"
            class="img-fluid"
          /></a
        >
      </div>
      <div class="col col-xs-6 col-md-9 align-self-center">
        <h1 class="text-light display-4">{title}</h1>
      </div>
    </div>
  </div>
</header>

<style>
  header {
    background-color: #25303b;
  }

  .black-hover-effect {
    border: 1px solid transparent;
    display: inline-block;
    padding: 1.5rem;
    text-align: center;

    transition: background-color 0.3s, border-color 0.3s;
  }
  .black-hover-effect:hover {
    background-color: black;
    border: 1px solid #f0f0f0;
  }
</style>
