<script>
  import { fade } from "svelte/transition";
  import constants from "../constants";
  import apiService from "../services/apiService";
  import Button from "./Button.svelte";
  import SpinnerButton from "./SpinnerButton.svelte";

  // Callback methods for when the search has been completed, and for if errors occur
  export let onSearchStarted;
  export let onSearchCompleted;
  export let onError;
  export let onReset;

  let uprn = "";
  const uprnRegex = /^[0-9]+$/;
  $: isValidUprn = uprnRegex.test(uprn);

  let message = "";
  let isBusy = false;
  let searchCompleted = false;

  const handleReset = () => {
    uprn = "";
    message = "";
    isBusy = false;
    searchCompleted = false;
    onReset();
  };

  const handleUprnChange = (e) => {
    const newUprn = e.target.value;

    if (searchCompleted && newUprn !== uprn) {
      searchCompleted = false;
    }

    uprn = newUprn;
  };

  const handleFindAddress = async () => {
    try {
      onSearchStarted();
      isBusy = true;
      const address = await apiService.uprnSearch(uprn);
      isBusy = false;
      searchCompleted = true;
      message = address == null ? constants.errorMessages.uprnNotFound : "";
      onSearchCompleted(address);
    } catch (errorMessage) {
      isBusy = false;
      searchCompleted = true;
      onError(errorMessage);
    }
  };
</script>

<div class="mt-5">
  <label for="postcode" class="form-label">Uprn</label>
  <input
    value={uprn}
    on:keyup={handleUprnChange}
    type="text"
    class="form-control max-vw-75"
    id="uprn"
    placeholder="Enter a unique property reference number (uprn) to begin"
  />
</div>
<div class="mt-3">
  {#if searchCompleted}
    <Button onClick={handleReset}>Start over</Button>
  {:else}
    <SpinnerButton onClick={handleFindAddress} disabled={!isValidUprn} {isBusy}>
      Find Address
    </SpinnerButton>
  {/if}
</div>

{#if message.length > 0}
  <p class="mt-3" in:fade>{message}</p>
{/if}

<style>
  input {
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #afb6bd;
    color: #4b4a4a;
    display: block;
    font-size: 1.125rem;
    line-height: 1.4;
    max-width: 100%;
    padding: 0.5rem;
    outline: 3px solid transparent;
    outline-offset: -3px;
    transition: box-shadow 0.2s ease, border-color 0.2s ease, outline 0.2s ease;
    width: 100%;
    box-shadow: 0 4px 0 0 #e3e6e5;
    margin: 0 0 6px;
  }
</style>
