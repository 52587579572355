<script>
  import ErrorMessage from "../components/ErrorMessage.svelte";
  import UprnSearch from "../components/UprnSearch.svelte";
  import AddressDetail from "../components/AddressDetail.svelte";

  let errorMessageRef;
  let selectedAddress;

  const handleSearchStarted = () => {
    handleReset();
  };

  const handleSearchCompleted = (address) => {
    selectedAddress = address;
    errorMessageRef.dismiss();
  };

  const handleSearchError = (error) => {
    errorMessageRef.show(error);
  };

  const handleReset = () => {
    selectedAddress = null;
    errorMessageRef.dismiss();
  };
</script>

<UprnSearch
  onSearchStarted={handleSearchStarted}
  onSearchCompleted={handleSearchCompleted}
  onReset={handleReset}
  onError={handleSearchError}
/>
<AddressDetail address={selectedAddress} />

<ErrorMessage bind:this={errorMessageRef} />
