<script>
  // Public methods
  export const show = (message) => {
    text =
      message.length > 0
        ? message
        : "Something has gone wrong. Please check your internet connection and try again.";
    isActive = true;
  };
  export const dismiss = () => {
    text = "";
    isActive = false;
  };

  // Private properties
  let isActive = false;
  let text = "";
</script>

{#if isActive}
  <div class="alert alert-danger mt-3" role="alert">
    {text}
  </div>
{/if}
