import constants from "../constants";

const postcodeSearch = async (postcode) => {
  // Default error message
  let errorMessage = constants.errorMessages.postcodeNotFound;

  try {
    // GMSConnector request
    const gmsConnectorResponse = await fetch(
      constants.serviceUrls.lookupByPostcode.GMSConnector + postcode
    );

    if (gmsConnectorResponse.ok) {
      const addresses = await gmsConnectorResponse.json();
      if (addresses.length > 0) {
        return addresses;
      }
    } else {
      errorMessage = constants.errorMessages.gmsConnectorDown;
    }
  } catch (error) {
    errorMessage = error;
  }

  // OSPlaces request
  try {
    const osPlacesResponse = await fetch(
      constants.serviceUrls.lookupByPostcode.OSPlaces + postcode
    );

    if (osPlacesResponse.ok) {
      const addresses = await osPlacesResponse.json();
      return addresses;
    } else {
      errorMessage = constants.errorMessages.osPlacesDown;
    }
  } catch (error) {
    errorMessage = error;
  }

  // If we get this far then both attempts failed to retrieve any data
  throw errorMessage;
};

const uprnSearch = async (uprn) => {
  let errorMessage = constants.errorMessages.osPlacesDown;

  // GMSConnector request
  try {
    const gmsConnectorResponse = await fetch(
      constants.serviceUrls.lookupByUprn.GMSConnector + uprn
    );

    if (gmsConnectorResponse.ok) {
      const address = await gmsConnectorResponse.json();
      if (address) {
        return address;
      }
    }
  } catch (error) {
    errorMessage = error;
  }

  try {
    // OSPlaces request
    const osPlacesResponse = await fetch(
      constants.serviceUrls.lookupByUprn.OSPlaces + uprn
    );

    if (osPlacesResponse.ok) {
      const address = await osPlacesResponse.json();
      return address;
    }

    if (osPlacesResponse.status === 404) {
      return null; // Not finding an address is not an error
    }
  } catch (error) {
    errorMessage = constants.errorMessages.osPlacesDown;
  }

  // If we get this far then both attempts failed to retrieve any data
  throw errorMessage;
};

export default {
  postcodeSearch,
  uprnSearch,
};
