<script>
  export let disabled = false;
  export let onClick;
</script>

<button {disabled} on:click={onClick} class="btn btn-primary">
  <slot />
</button>

<style>
  button {
    padding: 1.3rem 1.5rem;
    text-transform: uppercase;
    background-color: #12326e;
    border: none;
    letter-spacing: 0.05rem;
    line-height: 1.1;
  }
  button:disabled {
    background-color: rgba(90, 90, 90, 0.781);
  }
</style>
