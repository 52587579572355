<script>
  import { fade } from "svelte/transition";
  export let address;
</script>

{#if address}
  <div class="mt-5" in:fade>
    <div class="card">
      <div class="card-header">
        <h2><strong>{address.shortAddress}</strong></h2>
      </div>
      <div class="card-body">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Property</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Uprn</td>
              <td>{address.uprn}</td>
            </tr>
            <tr>
              <td>Northing</td>
              <td>{address.northing}</td>
            </tr>
            <tr>
              <td>Easting</td>
              <td>{address.easting}</td>
            </tr>
            {#if address.parish}
              <tr>
                <td>Parish</td>
                <td>{address.parish}</td>
              </tr>
            {/if}
            {#if address.ward}
              <tr>
                <td>Ward</td>
                <td>{address.ward}</td>
              </tr>
            {/if}
            {#if address.organisation}
              <tr>
                <td>Organisation</td>
                <td>{address.organisation}</td>
              </tr>
            {/if}
            {#if address.department}
              <tr>
                <td>Department</td>
                <td>{address.department}</td>
              </tr>
              {#if address.poBox}
                <tr>
                  <td>PO Box</td>
                  <td>{address.poBox}</td>
                </tr>
              {/if}
            {/if}
          </tbody>
        </table>
      </div>
    </div>
  </div>
{/if}
