<script>
  import Button from "./Button.svelte";

  export let onClick;
  export let disabled = false;
  export let isBusy = false;

  $: buttonDisabled = isBusy || disabled;
</script>

<Button {onClick} disabled={buttonDisabled}>
  {#if isBusy}
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    />
  {/if}
  <slot /></Button
>
