// const baseUrl = "https://addresses.york.gov.uk/"; // Test against live service
// const baseUrl = "https://localhost:44347/"; // Test against dev api server
const baseUrl = window.location.href;
const constants = {
  serviceUrls: {
    lookupByPostcode: {
      OSPlaces: baseUrl + "api/osplaces/lookupbypostcode/",
      GMSConnector: baseUrl + "api/address/lookupbypostcode/",
    },
    lookupByUprn: {
      OSPlaces: baseUrl + "api/osplaces/lookupbyuprn/",
      GMSConnector: baseUrl + "api/address/lookupbyuprn/",
    },
  },
  errorMessages: {
    postcodeNotFound: "No addresses were found for that postcode!",
    uprnNotFound: "No property was found for that uprn",
    gmsConnectorDown:
      "We are currently experiencing problems with our address search provider. Please try again later.",
    osPlacesDown:
      "We are currently experiencing problems with our os address search. Please try again later.",
  },
};

export default constants;
