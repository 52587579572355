<script>
  import { fade } from "svelte/transition";

  export let addresses;
  export let onAddressChanged;

  let selectedAddress;

  const handleChange = () => {
    if (selectedAddress) {
      onAddressChanged(selectedAddress);
    }
  };
</script>

{#if addresses && addresses.length > 0}
  <div class="mt-3" in:fade>
    <label for="addresses" class="form-label">Address</label>
    <select
      class="form-select max-vw-75"
      bind:value={selectedAddress}
      on:change={handleChange}
      id="addresses"
    >
      <option value={null}>Select an address</option>
      {#each addresses as address}
        <option value={address}>
          {address.shortAddress}
        </option>
      {/each}
    </select>
  </div>
{/if}

<style>
  select {
    background-color: #fff;
    border-radius: 0;
    border: 1px solid #afb6bd;
    color: #4b4a4a;
    display: block;
    font-size: 1.125rem;
    line-height: 1.4;
    max-width: 100%;
    padding: 0.5rem;
    outline: 3px solid transparent;
    outline-offset: -3px;
    transition: box-shadow 0.2s ease, border-color 0.2s ease, outline 0.2s ease;
    width: 100%;
    box-shadow: 0 4px 0 0 #e3e6e5;
    margin: 0 0 6px;
  }
</style>
