<script>
  import AddressDetail from "../components/AddressDetail.svelte";
  import AddressList from "../components/AddressList.svelte";
  import PostcodeSearch from "../components/PostcodeSearch.svelte";
  import ErrorMessage from "../components/ErrorMessage.svelte";

  let addresses;
  let selectedAddress;

  const handleSearchStarted = () => {
    handleReset();
  };

  const handleSearchCompleted = (results) => {
    addresses = results;
    selectedAddress = null;
  };

  const handlePostcodeSearchError = (message) => {
    errorMessageRef.show(message);
  };

  let errorMessageRef;

  const handleReset = () => {
    addresses = [];
    selectedAddress = null;
    errorMessageRef.dismiss();
  };
</script>

<PostcodeSearch
  onSearchStarted={handleSearchStarted}
  onSearchCompleted={handleSearchCompleted}
  onError={handlePostcodeSearchError}
  onReset={handleReset}
/>

<ErrorMessage bind:this={errorMessageRef} />

<AddressList
  {addresses}
  onAddressChanged={(address) => (selectedAddress = address)}
/>
<AddressDetail address={selectedAddress} />
